import React, {useState} from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { IMaskInput } from 'react-imask';
import Input from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Layout from '../layouts/DescriptionLayout';
import EmailIcon from '@mui/icons-material/Email';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const Contact = () => {
  const [telephone, setTelephone] = useState('');
  return (
    <Layout title="Contacto" url="/background/contactanos.jpg">
      <Container
        style={{
          marginTop: 50,
          marginBottom: 50
        }}
      >
        <Grid container>
          <Grid style={{padding: 10}} item xs={12} md={6}>
            <p style={{marginBottom: 20, textAlign: 'justify', marginRight: 10}}>
              ¡Contáctanos para poder hablar con una de nuestras asesoras!
              Por favor ingrese la información y pronto se le atenderá con sus necesidades.
              Por favor, trate de ser lo más específico con respecto a tamaños y
              no dudes en preguntarnos si tienes dudas. Si desea adjuntar un logo
              por favor en formatos .Png y/o .Jpg
            </p>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid className="text-center" item xs={6} md={3}>
                    <EmailIcon style={{fontSize: '2rem'}} />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    ventas@ditex.hn
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{padding: 10}} item xs={12} md={6}>
            <form enctype="multipart/form-data" target="_blank" action="https://formsubmit.co/ventas@ditex.hn" method="POST">
              <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth name="name" label="Nombre" variant="outlined" required />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth name="email" type="email" label="Correo" variant="outlined" required />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth name="city" type="text" label="Ciudad" variant="outlined" required />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth name="telephone" type="text" label="Teléfono" variant="outlined" required />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="message"
                    label="Mensaje"
                    variant="outlined"
                    multiline
                    rows={4}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl required fullWidth variant="outlined">
                    <Input
                      name="attachment"
                      inputComponent={(inputProp) => (
                        <input
                          {...inputProp}
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      )}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{marginTop: 15}} className="text-center">
                <Button style={{backgroundColor: '#137544'}} variant="contained" type="submit">
                  Enviar
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Contact;
