import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Layout from '../layouts/DescriptionLayout';
import AccordionData from '../components/Accordion';

const AboutUs = () => (
  <Layout title="Sobre Nosotros" url="/background/sastre.jpg">
    <Container>
      <div>
        <p className="text-center" >
          Somos una empresa familiar fundada en 1998 dedicándonos principalmente a la
          distribución de textiles al por mayor y al detalle con nueve sucursales a nivel nacional
          y en el 2003 ampliando nuestra línea de productos y servicios con la confección de uniformes
          corporativos a nivel nacional. Así mismo contamos con el mejor equipo capacitado en Asesoría
          de Imagen y Ventas Corporativas para plasmar tu identidad de marca en tus uniformes.
        </p>
      </div>
      {/* Mision y vision */}
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="containervisionmision">
            <h3 className="text-center">
              Misión
            </h3>
            <p>
              Satisfacer las necesidades de comercio Nacional y Regional, brindándole una excelente calidad
              en los productos y servicios que ofrecemos con la mejor selección y presentación de uniformes
              para cualquier rubro, para lograr un ambiente de superación para nuestros colaboradores, excelentes
              relaciones con nuestros proveedores y un rendimiento competitivo a nuestros accionistas.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="containervisionmision">
            <h3 className="text-center">
              Visión
            </h3>
            <p>
              Mantener el liderazgo en nuestros almacenes y oficinas de servicio, siendo la mejor opción para el
              comercio e industria en telas en general y en la solución de uniformes para cualquier rubro,
              conformándonos de un equipo de trabajo altamente calificado, tomando en cuenta siempre la honestidad,
              el respeto y el compañerismo rumbo a resultados satisfactorios para nuestra gente y nuestros clientes.
            </p>
          </div>
        </Grid>
      </Grid>
      {/* Valores */}
      <div style={{marginBottom: 50}}>
        <h2 className="text-center">
          Valores
        </h2>
        <AccordionData
          data={[
            {
              title: 'Integridad',
              description: 'Somos una organización no solo productiva sino comprometida con su comunidad.'
            },
            {
              title: 'Respeto',
              description: 'Damos valor a cada puesto de trabajo, al trabajo de todos y de cada uno de los miembros de la organización, a la función de cada uno.'
            },
            {
              title: 'Calidad',
              description: 'Brindamos desde la perspectiva del cliente calidad en nuestros productos y servicios.'
            },
            {
              title: 'Seguridad',
              description: 'Somos una organización que brinda seguridad a sus colaboradores y empleados principalmente en los productos y servicios que ofrecemos.'
            },
            {
              title: 'Honestidad',
              description: 'Usamos la verdad como herramienta para ganarnos la confianza y credibilidad de nuestros clientes.'
            },
            {
              title: 'Responsabilidad',
              description: 'Nuestra organización da cumplimiento de las obligaciones de sus colaboradores y ser cuidadoso en el proceso con el cliente para brindarles la mejor atención.'
            },
          ]}
        />
      </div>
    </Container>
  </Layout>
);

export default AboutUs;
