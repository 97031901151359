import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionData = ({data}) => (
  <div>
    {
      data.map((item) => (
        <Accordion key={item.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {item.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))
    }
  </div>
)

export default AccordionData;
