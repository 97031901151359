import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const CarouselImage = ({images}) => (
  <Carousel showStatus={false} showThumbs={false}>
    {
      images.map(({url, alt}) => (
        <div
          className='image-carousel'
          style={{
            height: '85vh',
            backgroundImage: `url(${url})`,
          }}
          key={alt}
        >
        </div>
      ))
    }
  </Carousel>
)

export default CarouselImage;
