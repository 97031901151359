import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const GridLeftImage = ({title, description, image, button, urlDownload, textButton}) => (
  <Grid container>
    <Grid item xs={12} md={12} lg={12}>
      <h2 style={{textAlign:'center',}}>
        {title}
      </h2>
    </Grid>
    <Grid className="text-center" item xs={12} md={6}>
      <img style={{width: '50%'}} src={image} alt={title} />
    </Grid>
    <Grid className="container-text-center" item xs={12} md={6}>
      <p style={{fontSize: 18, fontWeight: '500', textAlign: 'center'}}>
        {description}
      </p>
      {
        button && (
          <div className="text-center">
            <a href={urlDownload} download>
              <Button style={{background: '#137544', color: 'white'}}>
                {textButton}
              </Button>
            </a>
          </div>
        )
      }
    </Grid>
  </Grid>
);

export default GridLeftImage;
