import React from 'react';
import PrincipalLayout from './PrincipalLayout';

const DescriptionLayout = ({title, url, children}) => (
  <PrincipalLayout>
    <div
      className="layout-description"
      style={{
        backgroundImage: `url(${url})`,
        paddingTop: '20vh',
        paddingBottom: '20vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relativea'
      }}
    />
    <div style={{marginTop: -20, marginBottom: 50}} className="text-center">
      <span
        className="title-layout"
        style={{
          textAlign: 'center', margin: 'auto', color: 'white',
        }}
      >
        {title}
      </span>
    </div>
    {children}
  </PrincipalLayout>
);

export default DescriptionLayout;
