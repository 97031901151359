import React from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';

const CardStore = ({title, address, telephone, whatssapp}) => (
  <Paper style={{padding: 8, height: '100%'}} elevation={3}>
    <h5 className="text-center" style={{fontSize: 20}}>
      {title}
    </h5>
    <p>
      <span style={{marginRight: 10}} >
        <BusinessIcon />
      </span>
      {address}
    </p>
    <p>
      <span style={{marginRight: 10}} >
        <PhoneIcon />
      </span>
      {telephone}
    </p>
    <div className="text-center" >
      <a target="_blank" href={whatssapp} rel="noreferrer">
        <IconButton className="whatsappButton" >
          <WhatsAppIcon style={{color: 'white', fontSize: '2rem'}} />
        </IconButton>
      </a>
    </div>
  </Paper>
);

export default CardStore;
