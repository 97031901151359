import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Layout from '../layouts/DescriptionLayout';
import CardStore from '../components/CardStore';

const stores = [
  {
    title: 'Kennedy',
    address: 'Col. Kennedy frente a Supermercados Paiz, a la par de la antigua Escuela Normal Pedro Nufio.',
    telephone: 'Tel. 9450-0811',
    whatssapp: 'https://api.whatsapp.com/send/?phone=50494500811&text&app_absent=0'
  },
  {
    title: 'Choluteca',
    address: 'Frente al mercado municipal.',
    telephone: 'Tel. 9442-5951',
    whatssapp: 'https://wa.me/message/RYCXO6SGIEHJC1'
  },
  {
    title: 'Comayagua',
    address: 'Barrio El Torondon, frente al Parque Central, frente a Banco del País.',
    telephone: 'Tel. 9450-0805',
    whatssapp: 'https://wa.me/message/63Z24VW4WKJTD1'
  },
  {
    title: 'San Pedro Sula',
    address: 'Prolongación Pasaje Valle, 4ta Calle S.O., frente a Gobernación y Justicia.',
    telephone: 'Tel 9450-9345',
    whatssapp: 'https://api.whatsapp.com/send/?phone=50494509345&text&app_absent=0'
  },
  {
    title: 'El Progreso',
    address: 'Barrio Subirana, 1 calle, 1 y 2 avenida, frente a Banco Ficohsa.',
    telephone: 'Tel. 9614-0300',
    whatssapp: 'https://wa.me/message/GARHB5YCZOWQI1'
  },
  {
    title: 'Comayagüela',
    address: '4ta y 5ta avenida, 5ta calle, frente a La Curacao.',
    telephone: 'Tel. 9576-0941',
    whatssapp: 'https://api.whatsapp.com/send/?phone=50495760941&text&app_absent=0'
  },
  {
    title: 'La Ceiba',
    address: 'Avenida San Isidro, antiguo local Cine Tropicana.',
    telephone: 'Tel. 9539-0343',
    whatssapp: 'https://wa.me/message/5MNKDWLIM3GOE1'
  },
  {
    title: 'Tocoa',
    address: 'Barrio El Centro, Plaza Lisser, esquina opuesta a Banco Occidente.',
    telephone: 'Tel. 9474-6029',
    whatssapp: 'https://wa.me/message/JTDVFVLJMI6YJ1'
  },
  {
    title: 'Santa Rosa de Copán',
    address: 'Barrio Las Mercedes, frente al mercado central contiguo a mercadito Lidia, esquina opuesta a Banco Davivienda.',
    telephone: 'Tel. 9420-2678',
    whatssapp: 'https://wa.me/message/JTDVFVLJMI6YJ1'
  },
]

const Store = () => (
  <Layout title="Tiendas" url="/background/sastre.jpg">
    <Container style={{marginTop: 10, marginBottom: 50}}>
      <Grid container spacing={3}>
        {
          stores.map((item) => (
            <Grid key={item.title} item xs={12} md={4}>
              <CardStore {...item} />
            </Grid>
          ))
        }
      </Grid>
    </Container>
  </Layout>
);

export default Store;
