import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => (
  <div>
    <p>Error 404</p>
    <Link to="/">
      Ir a la página principal
    </Link>
  </div>
);

export default Error404;
