import React from 'react';
import Container from '@mui/material/Container';
import PrincipalLayout from '../layouts/PrincipalLayout';
import CarouselImage from '../components/CarouselImage';
import GridRightImage from '../components/GridRightImage';
import GridLeftImage from '../components/GridLeftImage';

// imagenes
import uniformesImage from '../assets/home/uniformes.png';
import telaImage from '../assets/home/c2.jpg';
import promocionalImage from '../assets/home/c3.jpg';

const images = [
  {
    url: '/carousel/splash1.jpg',
    alt: 'Imagen 1'
  },
  {
    url: '/carousel/splash2.jpg',
    alt: 'Imagen 2'
  },
]

const Home = () => (
  <PrincipalLayout>
    <CarouselImage images={images} />
    <Container style={{marginBottom: '1rem', marginTop: '1rem'}}>
      {/* Uniformes */}
      <GridLeftImage
        title="Uniformes"
        button
        textButton="Descargar Catálogo"
        urlDownload="/catalogo.pdf"
        description={`
          Vistiendo a Honduras con calidad, distinción y elegancia en los rubros:
          Corporativos, Escolares, Hotelería, Salud, Tipo de Pits y Mantenimiento.
          Te invitamos a descargar nuestro catálogo para ver nuestros surtidos.
        `}
        image={uniformesImage}
      />
      {/* Telas */}
      <GridRightImage
        title="Telas"
        description={`
          Las mejores Telas para dama, caballero, escolares, para hogar,
          para instituciones y especializada. Contáctanos para averiguar más
          sobre nuestras ubicaciones y surtido
        `}
        image={telaImage}
      />
      {/* Marcas */}
      {/* <GridLeftImage
        title="Marcas"
        description={`
          Unitex, Baby Mink Stormtech, Wadani, Wandani, Singapur,
          DiamondText, Text Licra, Super Cashmere Ingles, Tekro.
        `}
        image={uniformesImage}
      /> */}
      {/* Telas */}
      <GridRightImage
        title="Promocionales"
        description={`
          Artículos textiles promocionales para todos tus eventos y promociones corporativos.
          Contamos con: Corbata, pañoletas, gorras, veleros, estáticos.
          Contáctanos para averiguar más!
        `}
        image={promocionalImage}
      />
    </Container>
  </PrincipalLayout>
);

export default Home;
