import React from 'react';
import Grid from '@mui/material/Grid';

const GridRightImage = ({title, description, image}) => (
  <Grid container>
    <Grid item xs={12} md={12} lg={12}>
      <h2 style={{textAlign:'center',}}>
        {title}
      </h2>
    </Grid>
    <Grid className="container-text-center" item xs={12} md={6} lg={6}>
      <p style={{fontSize: 18, fontWeight: '500', textAlign: 'center'}}>
        {description}
      </p>
    </Grid>
    <Grid className="text-center" item xs={12} md={6} lg={6}>
      <img style={{width: '50%'}} src={image} alt={title} />
    </Grid>
  </Grid>
);

export default GridRightImage;
