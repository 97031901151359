import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrincipalLayout = ({children}) => (
  <div>
    <Navbar />
    {children}
    <Footer />
  </div>
);

export default PrincipalLayout;
