import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Layout from '../layouts/DescriptionLayout';

// images
import bordadoImage from '../assets/services/bordad.png';
import sublimacionImage from '../assets/services/sublimado.jpg';
import serigrafiaImage from '../assets/services/serigrafia.jpg';
import CardWithImage from '../components/CardWithImage';
import TalladoImage from '../assets/services/tallado.jpg';

// imagenes de asesoramiento
import person1 from '../assets/services/1.jpg';
import person2 from '../assets/services/5.jpg';
import person3 from '../assets/services/3.jpg';
import person4 from '../assets/services/4.jpg';

const services = [
  {
    title: 'Tallado y Confección',
    description: 'Contamos con el mejor equipo capacitado en Asesoría de Imagen y Ventas Corporativas para plasmar tu identidad de marca en tus uniformes.',
    image: TalladoImage
  },
  {
    title: 'Bordado',
    description: 'Te ofrecemos un relieve sobre tus prendas corporativas para plasmar la identidad corporativa de tu marca con el mejor equipo y profesionales.',
    image: bordadoImage
  },
  {
    title: 'Sublimación',
    description: 'Te ofrecemos la opción de sublimado a calor en tus prendas corporativas para aumentar la visibilidad de tu marca y para tus eventos.',
    image: sublimacionImage,
  },
  {
    title: 'Serigrafia',
    description: 'Nuestra empresa te ofrece el mejor estampado en tus uniformes corporativos para plasmar tu logotipo o frase, aumentando la visibilidad, atracción y fidelización hacia la empresa que representas.',
    image: serigrafiaImage
  },
];

const asesoramientoImages = [
  person1, person2, person3, person4
];

const Services = () => (
  <Layout title="Servicios" url="/background/sastre.jpg">
    <Container style={{marginTop: 10}}>
      <Grid spacing={1} container>
        {
          services.map((item) => (
            <Grid item xs={12} md={3}>
              <CardWithImage
                {...item}
              />
            </Grid>
          ))
        }
      </Grid>
      {/* Asesoramiento */}
      <div>
        <h2 className="text-center">
          Asesoramiento
        </h2>
        <p>
          Los asesores de imagen se encargan de analizar el aspecto de la persona para realzar sus puntos fuertes
          y potenciar su atractivo. De esta forma, consiguen generar el cambio desde dentro hacia fuera. Todos tenemos
          nuestra propia marca personal y lo que hacen los especialistas en imagen es sacar la mejor versión de nosotros mismos.
          Pero no solo nos orientan en la mejora de nuestro aspecto físico, sino que, además, nos ayudan a optimizar las relaciones
          con el entorno. Por ejemplo, nos guían en protocolo para adquirir buenos modales.
        </p>
        <Grid spacing={2} container>
          {
            asesoramientoImages.map((image) => (
              <Grid item xs={12} md={3}>
                <img style={{width: '100%'}} src={image} alt="Persona" />
              </Grid>
            ))
          }
        </Grid>
      </div>
    </Container>
  </Layout>
);

export default Services;
