import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import logo from '../assets/logo blanco.png'

const Footer = () => (
  <div style={{backgroundColor: '#137544', color: 'white', padding: '15px'}}>
    <Container style={{marginBottom: '15px'}}>
      <Grid container>
        <Grid className="text-center" item md={6}>
          <img style={{width: '150px', margin: 'auto'}} src={logo} alt="DITEX" />
        </Grid>
        <Grid className="text-center" item md={6}>
          <div style={{width: '60%', margin: 'auto'}}>
            <Typography variant='h5'>
              Redes Sociales
            </Typography>
            <div>
              <a href='https://www.facebook.com/ditexhn' target="_blank" rel="noreferrer">
                <IconButton>
                  <FacebookIcon style={{color: 'white', fontSize: '32px'}} />
                </IconButton>
              </a>
              <a href='https://www.instagram.com/ditexhn/' target="_blank" rel="noreferrer">
                <IconButton>
                  <InstagramIcon style={{color: 'white', fontSize: '32px'}} />
                </IconButton>
              </a>
              <a href='https://api.whatsapp.com/send/?phone=50494544686&text&app_absent=0' target="_blank" rel="noreferrer">
                <IconButton>
                  <WhatsAppIcon style={{color: 'white', fontSize: '32px'}} />
                </IconButton>
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
    <div style={{padding: '8px', backgroundColor: '#167043'}}>
      <Container>
        <Typography style={{fontSize: '12px'}} align='center'>
          Copyright - DITEX HN ©2022 All rights reserved
        </Typography>
      </Container>
    </div>
  </div>
);

export default Footer;
